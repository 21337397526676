import verConfig from "./verConfig"
import dataDetail from "./dataDetail"
import moneyFormat from "./formatMoney"
export default function(){
  const data = []
  for (let i = 0; i < 100; i++) {
    data.push({
      name: randName(),
      money: randMoney(),
    })
  }
  return data
}
function randName() {
  let name =
    `+${verConfig.areaCode}\n` +
    String(dataDetail.rand(20, 99)) +
    dataDetail.rand(3, 9) +
    '****' +
    parseInt(Math.random() * 10) +
    parseInt(Math.random() * 10) +
    parseInt(Math.random() * 10)
  return name
}
function randMoney() {
  const { homeProfitIntervalMax, homeProfitInterval } = verConfig
  let interval = homeProfitInterval || [100, 300000]

  if (homeProfitIntervalMax) {
    if (dataDetail.rand(0, 10) < 2) {
      interval = homeProfitIntervalMax
    }
  }
  let probability = dataDetail.rand(0, 100)
  let res = 0
  if (probability <= 30) {
    // if(verConfig.homeProfitFormat){
    //   return moneyFormat(dataDetail.rand(interval[0], interval[1] / 20))
    // }
    return dataDetail.rand(interval[0], interval[1] / 20)
  } else {
    // if(verConfig.homeProfitFormat){
    //   return moneyFormat(dataDetail.rand(interval[1] / 20, interval[1]))
    // }
    return dataDetail.rand(interval[1] / 20, interval[1])
  }
}